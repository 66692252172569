import React, { useState, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';

// Material components
import { IconButton, Divider, Menu, MenuItem } from '@mui/material';

// Material icons
import MenuIcon from '@mui/icons-material/Menu';

//Context
import { useRelyingParty } from '../../context/RelyingPartyContext';

// Components
import Alert from '../layout/Alert';
import NewVersionChecker from './NewVersionChecker';

//Styles
import styles from './Navbar.module.css';

// Utils
import { ctxValue } from '../../utils/config';
import { logout } from '../../utils/services';
import { getAccessToken } from '../../utils/session';
import { jwtDecode } from 'jwt-decode';
import { useAlert } from 'react-alert';

const Navbar = () => {
  const alert = useAlert();
  const location = useLocation();
  const {relyingParty} = useRelyingParty();
  const [logoutTimerId, setLogoutTimerId] = useState(null);
  const idleTimer = useRef(null);
  const history = useHistory();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuOpen = Boolean(menuAnchor);
  const [alerts, setAlerts] = useState([]);
  const idleTimeoutSeconds = relyingParty?.agentIdleTimeoutSeconds || (8 * 60 * 60) - (5 * 60); // (8 hours) - (5 minutes)

  //console.debug(`Navbar() relyingParty: ${JSON.stringify(relyingParty,null,3)}`);

  function showNavbar() {
    if (location?.pathname === '/'               ) return false;
    if (location?.pathname === '/forgot-password') return false;
    if (location?.pathname === '/proofs-review'  ) return false;
    if (location?.pathname.startsWith('/reset-password')) return false;
    return true;
  }

  const menuClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const menuClose = () => {
    setMenuAnchor(null);
  };

  const menuSignOut = () => {
    menuClose();
    logout();
    history.push({ pathname: '/', hash: ctxValue('SUBDOMAIN') });
  };

  const tokenTimestampToDate = (ts) => {
    if (!ts) return;
    const d = new Date(ts*1000);
    return d.toLocaleString();
  };

  const menuInfo = async () => {
    menuClose();
    const {token} = await getAccessToken();
    if (!token) {
      alert(`There is no access token`);
      return;
    }
    const decoded   = jwtDecode(token);
    // AAD tokens will have a 'name' claim, IDgo tokens have 'agentFirstName' and 'agentLastName'
    const agentName = decoded?.name  || `${decoded?.agentFirstName} ${decoded?.agentLastName}` || 'missing';
    const aud       = decoded?.aud   || decoded?.agentId || 'missing';
    const tid       = decoded?.tid   || 'IDgo managed account';
    const iat       = tokenTimestampToDate(decoded?.iat) || '';
    const exp       = tokenTimestampToDate(decoded?.exp) || '';
    const roles     = decoded?.roles || [];
    const msg = <>
        <div className={styles.infoAlertWrapper}>
          <span className={styles.infoAlerteHdr}>Agent Information</span>
          <div className={styles.infoAlerteMsg}>
            <div className={styles.infoAlerteMsgItemLeft}><b>Name</b></div><div className={styles.infoAlerteMsgItemLeft}>{agentName}</div>
            <div className={styles.infoAlerteMsgItemRight}>aud</div><div className={styles.infoAlerteMsgItemLeft}>{aud}</div>
            <div className={styles.infoAlerteMsgItemRight}>tid</div><div className={styles.infoAlerteMsgItemLeft}>{tid}</div>
            <div className={styles.infoAlerteMsgItemLeft}><b>Token</b></div>
            <div className={styles.infoAlerteMsgItemLeft}>
              <>&rArr; {iat} &nbsp; issued<br/></>
              <>&rArr; {exp} &nbsp; expires</>
            </div>
            <div className={styles.infoAlerteMsgItemLeft}><b>Roles</b></div>
            <div className={styles.infoAlerteMsgItemLeft}>
              {roles.includes('admin')            ? <>&rArr; Is an adminsitrator<br/></> : ''}
              {roles.includes('auto-enroller')    ? <>&rArr; Can enroll users<br/></> : ''}
              {roles.includes('revoker')          ? <>&rArr; Can revoke enrollments<br/></> : ''}
              {roles.includes('non-whitelist-ok') ? <>&rArr; Non-whitelisted access allowed<br/></> : ''}
              {roles.includes('view-reports')     ? <>&rArr; Can view IDgo Admin reports<br/></> : ''}
              {roles.includes('account-deleter')  ? <>&rArr; Can delete IDgo accounts<br/></> : ''}
            </div>
          </div>
        </div>
      </>
    alert.show(msg);
  };

  // Any mouse movements will reset the onIdle timer. The user does not have to 'click' the message as noted.
  const onActive = async (e) => {
    if (alerts && alerts.constructor===Array && alerts.length>0) {
      idleTimer.current.reset();
      setAlerts([]);
      clearTimeout(logoutTimerId);
      setLogoutTimerId(null);
    }
  };

  const onIdle = (_e) => {
    const idleDurationTimestamp = new Date(idleTimeoutSeconds * 1000).toISOString();
    const idleHH = idleDurationTimestamp.slice(11,13);
    const idleMM = idleDurationTimestamp.slice(14,16);
    let idleTime = '';
    if (idleHH!=='00') idleTime += `${idleHH} hours and `
    if (idleMM!=='00') idleTime += `${idleMM} minutes`
    if (!idleTime) {
      const idleSS = idleDurationTimestamp.slice(17,19);
      idleTime = `${idleSS} seconds`
    }
    const msg = `Are you still there? You have been inactive for at least ${idleTime}.
                Click on me or you will be logged out in 5 minutes.`;
    setAlerts([...alerts, {heading: `Hey!`, msg, type:'warning'}]);
    const logoutTimeoutSeconds = relyingParty?.agentLogoutTimeoutSeconds || (5 * 60); // 5 minutes
    const currentLogoutTimerId = setTimeout(() => {
        setAlerts([]);
        logout();
        window.location.reload();
      }, logoutTimeoutSeconds*1000);
    setLogoutTimerId(currentLogoutTimerId);
  };

  return (
    <>
      { showNavbar() &&
        <>
          <NewVersionChecker />
          <IdleTimer
            ref={idleTimer}
            element={document}
            // TODO: onActive will get called automatically when it shouldn't be. Fix this!
            //       This tends to happen after a single alert appears and is then dismissed. No idea what is causing this.
            onActive={(e) => onActive(e)}
            onIdle={(e) => onIdle(e)}
            debounce={500}
            timeout={idleTimeoutSeconds*1000}
          ></IdleTimer>
          
          <Alert alerts={alerts}/>

          <div>
            <IconButton
              className={styles.menuButton}
              id='menu-main-button'
              aria-controls={menuOpen ? 'menu-main' : undefined}
              aria-haspopup='true'
              aria-expanded={menuOpen ? 'true' : undefined}
              onClick={menuClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-main'
              anchorEl={menuAnchor}
              open={menuOpen}
              onClose={menuClose}
              MenuListProps={{'aria-labelledby':'menu-main-button'}}
            >
              <MenuItem dense={true} onClick={menuClose} component={Link} to={'/change-email#'+ctxValue('SUBDOMAIN')} data-testid='menuitem-change-email'>Change Email Address</MenuItem>
              <MenuItem dense={true} onClick={menuClose} component={Link} to={'/change-password#'+ctxValue('SUBDOMAIN')} data-testid='menuitem-change-password'>Change Password</MenuItem>
              <Divider />
              <MenuItem dense={true} onClick={menuInfo} data-testid='menuitem-info'>Info</MenuItem>
              <Divider />
              <MenuItem dense={true} onClick={menuSignOut} data-testid='menuitem-signout'>Sign Out</MenuItem>
            </Menu>

            <h6 className={styles.pageHeader}>IDgo Agent - {relyingParty?.name}</h6>
          </div>
        </>
      }
    </>
  );
};

export default Navbar;